import axios from "axios";

export default class API {
  static async getAPI(req, headers , extraAttributes = {}) {
    try {
      let params =  {...extraAttributes , ...req.user_attribute}
      return await axios.get(req.url, {params, headers: {...headers} })
    }catch (e) {
      console.log(e)
    }

  }
  static async postAPI(req, headers, extraAttributes = {}) {
    try {
      let payload =  {...extraAttributes , ...req.user_attribute}
     return  await axios.post(req.url, payload, { headers: {...headers} })
    } catch (e) {
      console.log(e)
    }
  }

}
