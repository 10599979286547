<template>
  <div style="position: relative">
    <div v-if="loading" class="center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div class="list-address">
      <v-overlay :value="dialog" class="d-flex justify-center">
        <div class="overlay-container pa-5">
          <div class="close-btn-container mb-1">
            <v-icon size="25" class="cursor-pointer" @click="dialog = false">
              mdi-close</v-icon
            >
          </div>
          <h4 class="ff-bold font-xl dark-gray-font mb-4 px-2 ff-bold">
            {{ $t('We are sorry') }}
          </h4>
          <p class="ff-medium font-md dark-gray-font mb-7 px-2 ff-regular">
            {{ error_msg }}
          </p>
        </div>
      </v-overlay>
      <div class="close-btn-container mb-1 px-1 d-block d-md-none">
        <v-btn
          icon
          text
          @click="pageStatus = { ...pageStatus, showAddresses: false }"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <h3 class="title ff-bold">{{ $t('Delivering Address') }}</h3>
      <v-radio-group
        @change="selectAddress"
        v-if="$store.state.order_map.oldAddresses.length > 0"
        class="mt-0"
        v-model="selected"
        column
      >
        <v-radio
          v-for="(address, index) in $store.state.order_map.oldAddresses"
          :key="index"
          :value="address"
          class="mt-5 mb-4"
        >
          <template slot="label">
            <div class="address-text">
              <div class="address-desc">{{ address.desc }}</div>
              <div class="addresses-divider-container mt-2 mb-0">
                <v-divider class="addresses-divider"></v-divider>
              </div>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <p v-else>{{ $t('No addresses available') }}</p>
      <span
        text
        class="add-new-address"
        depressed
        @click="pageStatus.showAddresses = false"
      >
        <v-icon class="mb-1"> mdi-plus </v-icon>
        <span class="text-decoration-underline ff-regular">
          {{ $t('Add New Address') }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Formatter } from 'sarala-json-api-data-formatter';
const formatter = new Formatter();
export default {
  data: function () {
    return {
      selected: null,
      loading: false,
      dialog: false,
      error_msg: '',
    };
  },
  methods: {
    selectAddress(v) {
      this.loading = true;
      let dataForExistedAddress = {
        order_id: this.order_id,
        address_id: v.id,
      };
      this.mapAddress = { lat: v.lat, lon: v.lon };
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/addresses?include=discount,meals.mealsAttributeSets`,
        headers: {},
        data: dataForExistedAddress,
      })
        .then((res) => {
          let data = formatter.deserialize(
            JSON.parse(JSON.stringify(res.data))
          );
          this.discountCode = data.discount ? data.discount.data : {};
          this.basket = data.meals.data;
          this.paymentDetails = data.cost;
          this.order = this.updateOrderToHaveSelectedIfAny(data);
          console.log("updated Order", this.order);
          this.notes = data.notes || '';
          this.meals = data.menu.data;
          this.address = { ...this.address, address_id: data.address.data.id };
          this.$router.push({
            path: '/order/ext',
            query: {
              user_id: this.$route.query.user_id,
              bot_id: this.$route.query.bot_id,
            },
          });
        })
        .catch((e) => {
          this.dialog = true;
          this.error_msg = e.response.data.errors[0].detail;
          console.log('error', e);
        })
        .finally(() => (this.loading = false));
    },

    updateOrderToHaveSelectedIfAny(order){
      let mealsArr = order.meals.data;
      for (let i = 0; i < mealsArr.length; ++i) {
        let meal = mealsArr[i];
        for (let j = 0; j < meal.mealAttributeSets.data.length; ++j){
          meal.mealAttributeSets.data[j].selected = [];
          meal.mealAttributeSets.data[j].selectedPrice = 0;
          let options = meal.mealAttributeSets.data[j].mealsAttributeSetOption.data;
          for (let k = 0; k <options.length; ++k) {
            //console.log("selectedOption" , options);
            meal.mealAttributeSets.data[j].selected.push(options[k].id);
            meal.mealAttributeSets.data[j].selectedPrice += options[k].price;
          }
        }
        mealsArr[i] = meal;
      }
      order.meals.data = mealsArr;
      return order;
    }
  },
  computed: {
    discountCode: {
      get() {
        return this.$store.state.order_map.mapAddress;
      },
      set(value) {
        this.$store.commit('updateDiscountCode', value);
      },
    },
    mapAddress: {
      get() {
        return this.$store.state.order_map.mapAddress;
      },
      set(value) {
        this.$store.dispatch('setMapAddress', value);
      },
    },
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },
    notes: {
      get() {
        return this.$store.state.order_ext.notes;
      },
      set(value) {
        this.$store.commit('updateNotes', value);
      },
    },

    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
    meals: {
      get() {
        return this.$store.state.order_ext.meals;
      },
      set(value) {
        this.$store.commit('updateMeals', value);
      },
    },

    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },
    pageStatus: {
      get: function () {
        return this.$store.state.order_map.pageStatus;
      },
      set: function (value) {
        this.$store.dispatch('setPageStatus', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/list-addresses.scss';

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
</style>
