export default {
    createUpdatedOrder: function (order, address_id, notes, basket){
        basket = this.addOriginalOrder(basket);

        for (let i = 0; i < basket.length; ++i) {
            basket = this.createCopyNumber(basket, basket[i]);
        }

        //console.log("basket after creating copy", basket);


        let newOrder = {
            client: {
                name: order.client.full_name,
                mobile: order.client.mobile,
                address: order.client.address,
                email: order.client.email,
                address_id: address_id,
            },
            vendor_bot_id: order.vendor.bot_id,
            branch_id: order.branch ? order.branch.id : 8,
            status: 'draft',
            pickup_note: order.pickup_note,
            notes: notes,
            items: basket.map((e) => ({
                id: e.id.indexOf('-') > -1 ? e.id.split('-')[1] : e.id,
                quantity: e.quantity,
                comment: e.comment? e.comment : "",
                options: this.getItemSelectedOptions(e),
                copy_number: e.copy_number? e.copy_number : 1
            })),
        };
        return newOrder;
    },
    sortBasket: function (basket){
        basket.sort( function( a , b){
            if(a.order > b.order) return 1;
            if(a.order < b.order) return -1;
            return 0;
        });
        return basket;
    },
    addOriginalOrder: function(arr){
        if(Array.isArray(arr)){
            arr.map((item, index) => {
                item.order = index
                return item
            })
        }
        return arr;
    },
    createCopyNumber: function(basket, item){
        var sameItems = this.getAllItemsWithSameID(basket, item)[0];
        var restItems = this.getAllItemsWithSameID(basket, item)[1];
        //console.log("sameItems", sameItems);
        //console.log("rest", restItems);

        let copy_number = 1;
        for (let i = 0; i < sameItems.length; ++i) {
            sameItems[i].id = item.id.indexOf('-') > -1 ? item.id.split('-')[1] : item.id
            sameItems[i].copy_number = copy_number;
            restItems.push(sameItems[i]);
            copy_number++;
        }

        //console.log("rest after copy", restItems);

        return this.sortBasket(restItems);
    },
    combineSelected: function(item){
        var combined = "";
        if(Array.isArray(item)){
            item.forEach((option) => {
                if (Array.isArray(option.selected)) {
                    option.selected.forEach((attr) => {
                        combined += attr + " ";
                    })
                } else if(option.selected) combined += option.selected + " "
            })
        }
        return combined;
    },
    getAllItemsWithSameID: function(arr, newValue){
        var result = [];
        var rest = [];
        if(Array.isArray(arr)){
            arr.forEach((item) => {
                let elSplitValue = item.id.indexOf('-') > -1 ? item.id.split('-')[1] : item.id;
                let newSplitValue = newValue.id.indexOf('-') > -1 ? newValue.id.split('-')[1] : newValue.id
                if (elSplitValue === newSplitValue) {
                    result.push(item)
                }else{
                    rest.push(item)
                }
            })
        }
        return [result, rest];
    },
    getItemSelectedOptions: function (item) {
        let arr = [];
        item.mealsAttributeSets = item.mealsAttributeSets ? item.mealsAttributeSets : item.mealAttributeSets.data;
        for (let i = 0; i < item.mealsAttributeSets.length; ++i) {
            let selected_options = item.mealsAttributeSets[i];
            if (Array.isArray(selected_options.selected)) {
                for (let j = 0; j < selected_options.selected.length; ++j) {
                    arr.push({"attribute_set_option_id": selected_options.selected[j]});
                }
            } else if (selected_options.selected) {
                arr.push({"attribute_set_option_id": selected_options.selected});
            }
        }
        return arr;
    },
}
