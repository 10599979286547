import Vue from 'vue';
import Vuex from 'vuex';
import { order_ext } from './modules/order_ext';
import { core } from './modules/core';
import { bookingSteps } from './modules/bookingSteps';
import { order_map } from './modules/order_map';
import createPersistedState from 'vuex-persistedstate';
const dataState = createPersistedState({
  paths: ['order_map', 'order_ext'],
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    order_ext,
    core,
    bookingSteps,
    order_map,
  },
  plugins: [dataState],
});

export default store;
