<template>
  <div :class="this.$i18n.locale + ' location-container'" :style="userStyle">
    <add-location :locations="locations" />
    <loader :loader="loading" />
    <link-expired
      v-if="expired"
      :WARedirectNo="channelData.WARedirectNo"
      :FBRedirectPageNo="channelData.FBRedirectPageNo"
      :channel="channel"
      :TWRedirectPageNo="channelData.TWRedirectPageNo"
    />
  </div>
</template>

<script>
import { Formatter } from 'sarala-json-api-data-formatter';
import i18n from '../../i18n';
const formatter = new Formatter();
import axios from 'axios';
import Loader from '../../common/loader.vue';
import LinkExpired from '../../errors/link-expired';

import AddLocation from './components/map/add-location.vue';
export default {
  components: {
    AddLocation,
    Loader,
    LinkExpired,
  },
  data() {
    return {
      head: {
        title: {
          inner: 'Beena',
        },
      },

      main_color: '',
      loading: true,
      expired: false,
      branches: [],
      locations: [],
    };
  },
  async mounted() {
    await this.$store.dispatch('resetMapState');
    await this.getStyles();
  },
  methods: {
    getData() {
      axios
        .get(
          ` ${process.env.VUE_APP_API_ROOT_BEENA}user_id=${this.$route.query.user_id}&bot_id=${this.$route.query.bot_id}&slug=order`
        )
        .then((res) => {
          if (res.data.not_found) {
            this.expired = true;
            this.loading = false;
          }
          this.header = res.data.webview_header;
          let title = document.getElementsByTagName('title')[0];
          title.innerText = res.data.webview_header || 'Beena';
          this.description = res.data.webview_description;
          this.channel = res.data.channel;
          this.cancelRedirectBlock = res.data.cancelRedirectBlock;
          i18n.locale = res.data.lang || 'ar';
          localStorage.setItem('locale', res.data.lang || 'ar');

          if (!res.data.requestGetOrder) {
            this.loading = false;
          }

          this.channelData.WARedirectNo = res.data.number;
          this.channelData.FBRedirectPageNo = res.data.page_id;
          this.channelData.TWRedirectPageNo = res.data.twitter_id;
          this.loading = false;
          this.requestSubmitOrder = res.data.requestSubmitOrder;
          let arr = res.data.requestGetOrder.url.split('/show/');
          if (!arr[1]) {
            this.expired = true;
            this.loading = false;
          }
          this.order_id = arr[1];
        })
        .then(() => {
          axios
            .get(
              `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/vendors/customer/list-vendor-branches/${this.order_id}`
            )
            .then((res) => {
              let data = formatter.deserialize(
                JSON.parse(JSON.stringify(res.data))
              );
              let locations = [];
              locations = data?.data.map((e) => {
                return {
                  lat: e.location.lat,
                  lon: e.location.long,
                  info: e.name,
                };
              });
              this.locations = locations.length
                ? locations
                : [{ lat: '', lon: '', info: '' }];
            });
        })
        .then(() => {
          axios
            .get(
              `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/addresses?order_id=${this.order_id}`
            )
            .then((res) => {
              let data = JSON.parse(
                JSON.stringify(formatter.deserialize(res.data))
              );
              let arr = [];
              data.data.map((add) => {
                arr.push({
                  id: add.id,
                  desc: add.address,
                  lat: add.location.latitude,
                  lon: add.location.longitude,
                });
              });
              this.$store.dispatch('setOldAddress', arr);
            });
        });
    },
    getStyles() {
      axios
        .get(
          `${process.env.VUE_APP_ENV_ROOT_BEENA}/bots/${this.$route.query.bot_id}/widget-settings?lang=en_EN`
        )
        .then((res) => {
          this.main_color = res.data.data.mainColor;
          this.mainColor = res.data.data.mainColor;
          this.logo = res.data.data.logo;
          let faviconLink = document.getElementById('favicon');
          faviconLink.setAttribute('href', res.data.data.logo);
          faviconLink.setAttribute('type', 'image/png');
          this.getData();
        });
    },
  },
  computed: {
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },
    header: {
      get: function () {
        return this.$store.state.order_ext.header;
      },
      set: function (value) {
        this.$store.commit('updateHeader', value);
      },
    },
    description: {
      get: function () {
        return this.$store.state.order_ext.description;
      },
      set: function (value) {
        this.$store.commit('updateDescription', value);
      },
    },
    channel: {
      get: function () {
        return this.$store.state.order_ext.channel;
      },
      set: function (value) {
        this.$store.commit('updateChannel', value);
      },
    },
    channelData: {
      get: function () {
        return this.$store.state.order_ext.channelData;
      },
      set: function (value) {
        this.$store.commit('updateChannelData', value);
      },
    },
    requestSubmitOrder: {
      get: function () {
        return this.$store.state.order_ext.requestSubmitOrder;
      },
      set: function (value) {
        this.$store.commit('updateRequestSubmitOrder', value);
      },
    },
    cancelRedirectBlock: {
      get: function () {
        return this.$store.state.order_ext.cancelRedirectBlock;
      },
      set: function (value) {
        this.$store.commit('updateCancelRedirectBlock', value);
      },
    },

    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
    logo: {
      get() {
        return this.$store.state.order_ext.logo;
      },
      set(value) {
        this.$store.commit('updateLogo', value);
      },
    },

    userStyle() {
      return {
        '--main-color': this.main_color,
      };
    },
  },
};
</script>

<style lang="scss">
.location-container {
  .ar {
    direction: rtl !important;
    .v-text-field__details {
      .v-messages {
        text-align: right !important;
      }
    }
  }
}
</style>
