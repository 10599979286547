const getDefaultState = () => {
  return {
    pageStatus: {
      showDetails: false,
      showForm: false,
      showAddresses: false,
    },
    address: {
      address_id: null,
      type: '',
      street: '',
      building: '',
      apartment: '',
      floor: '',
      notes: '',
    },
    mapAddress: {
      lat: '',
      lon: '',
    },
    oldAddresses: [],
    overLay: {
      status: false,
      message: 'hello world',
    },
    selected_address: {},
  };
};

export const order_map = {
  state: {
    pageStatus: {
      showDetails: false,
      showForm: false,
      showAddresses: false,
    },
    address: {
      address_id: null,
      type: '',
      street: '',
      building: '',
      apartment: '',
      floor: '',
      notes: '',
    },
    mapAddress: {
      lat: '',
      lon: '',
    },
    oldAddresses: [],
    overLay: {
      status: false,
      message: 'hello world',
    },
    selected_address: {},
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },

    SELECTED_ADDRESS(state, address) {
      state.selected_address = { ...address };
    },
    SET_ADDRESS(state, address) {
      state.address = { ...address };
    },
    SET_PAGE_STATUS(state, status) {
      state.pageStatus = { ...status };
    },
    SET_MAP_ADDRESS(state, mapAddress) {
      state.mapAddress = { ...mapAddress };
    },
    SET_OLD_ADDRESS(state, addresses) {
      state.oldAddresses = [...addresses];
    },
    SET_OVERLAY(state, overLay) {
      state.overLay = { ...overLay };
    },
  },
  actions: {
    resetMapState({ commit }) {
      commit('RESET_STATE');
    },
    setSelectedAddress({ commit }, address) {
      commit('SELECTED_ADDRESS', address);
    },
    setAddress({ commit }, address) {
      commit('SET_ADDRESS', address);
    },
    setPageStatus({ commit }, status) {
      commit('SET_PAGE_STATUS', status);
    },
    setMapAddress({ commit }, mapAddress) {
      commit('SET_MAP_ADDRESS', mapAddress);
    },
    setOldAddress({ commit }, addresses) {
      commit('SET_OLD_ADDRESS', addresses);
    },
    setOverLay({ commit }, overLay) {
      commit('SET_OVERLAY', overLay);
    },
  },
};
