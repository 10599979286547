<template>
  <v-container fluid :class="$i18n.locale + ' summery-container pa-0 ma-0'" :style="userStyle">
    <v-overlay :value="dialog" class="d-flex justify-center">
      <div class="overlay-container pa-5">
        <div class="close-btn-container mb-1">
          <v-icon size="25" class="cursor-pointer" @click="dialog = false">
            mdi-close
          </v-icon
          >
        </div>
        <h4 class="ff-bold font-xl dark-gray-font mb-4 px-2 ff-bold">
          {{ $t('We are sorry') }}
        </h4>
        <p class="ff-medium font-md dark-gray-font mb-7 px-2 ff-regular">
          {{ error_msg }}
        </p>
      </div>
    </v-overlay>
    <v-row class="summery-parent pa-0 ma-0">
      <v-col class="summery-main pa-12 px-4 px-lg-12 px-md-6 ma-0" cols="12" sm="12" md="8">
        <v-row class="px-5">
          <v-col class="pa-0">

            <div :class="`back-arrow-container d-block d-md-none ${$i18n.locale}`">
              <v-icon
                  :color="mainColor"
                  size="25"
                  class="cursor-pointer"
                  @click="$router.push({
        path: '/order/ext',
        query: { ...$route.query },
     })"
              >
                mdi-arrow-left
              </v-icon
              >
            </div>
            <h3 class="mb-4 ff-bold font-xl dark-gray-color ">{{ $t("Delivery Address") }}</h3>
            <v-card class="pa-0 ma-0 map-card">
              <v-container fluid>
                <v-row class="pa-0">
                  <v-col class="pa-0" sm="3">
                    <v-img
                        :src="`https://maps.googleapis.com/maps/api/staticmap?zoom=16&size=600x300&maptype=roadmap&markers=color:red:B%7C${this.$store.state.order_map.mapAddress.lat},${this.$store.state.order_map.mapAddress.lon}&key=AIzaSyBNK-iGEM5uYffXvIZ98l676iOhyxFLYRE`"
                        height="100%"
                    ></v-img>
                  </v-col>
                  <v-col sm="9" class="pa-5 d-flex justify-center flex-column">
                    <div class="d-flex justify-space-between">


                    </div>
                    <div class="dark-gray-font my-2 ff-regular">
                      {{ order.address.data.address }}
                    </div>
                    <div class="light-gray-font ff-regular">
                      {{ this.order.client.mobile }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="px-5 mt-10">
          <v-col class="pa-0">
            <h3 class="mb-4 ff-bold font-xl dark-gray-color">{{ $t("Delivery Time") }}</h3>
            <v-card class="pa-0 ma-0 map-card">
              <v-container fluid>
                <v-row class="pa-0">
                  <v-col sm="12" class="pa-5 d-flex justify-start align-center image-container">
                    <img
                        class=""
                        width="33"
                        src="../../assets/img/ic_delivery.svg"
                    />
                    <span class="mx-5 ff-regular dark-gray-font">{{ $t("Delivery within ") }}
                        {{ order.delivery_at }}
                        {{ $t("minutes") }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-10 px-5">
          <v-col class="pa-0">
            <h3 class="mb-4 ff-bold font-xl dark-gray-color">{{ $t("Payment Method") }}</h3>
            <v-card class="pa-0 ma-0 map-card">
              <v-container fluid>
                <v-row class="pa-0">
                  <v-col sm="12" class="pa-5 d-flex justify-start align-center image-container">
                    <img
                        class=""
                        width="33"
                        src="../../assets/img/ic_cash.svg"
                    />
                    <span class="mx-5 dark-gray-font ff-regular">{{ $t("Cash") }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="summery-total pa-12 px-6 px-lg-12 ma-0" cols="12" sm="12" md="4">
        <!-- My order -->
        <div>
          <h3 class="font-xl ff-bold dark-gray-font mb-6">{{ $t("my order") }}</h3>
          <template v-for="(order, index) in basket">
            <div :key="index" class="pb-2 mb-2">
              <added-order
                  :title="order.name"
                  :options="order.mealsAttributeSets? [...order.mealsAttributeSets] : (order.mealAttributeSets? order.mealAttributeSets.data : [])"
                  :index="index"
                  :id="order.id"
                  :desc="order.description"
                  :count="order.quantity"
                  :price="order.price"
                  :item="order"
                  :currency="order.currency"
                  :comment="order.comment"
                  :order-summary="true"/>
            </div>
            <v-divider class="pb-4"/>
          </template>
        </div>

        <!-- Notes -->

        <div class="mt-4">
          <h3 class="font-xl ff-bold dark-gray-font mb-6">{{ $t("special notes") }}</h3>

          <p class="font-md ff-regular" v-if="notes">{{ notes }}</p>
          <p class="font-md ff-regular" v-else>{{ $t('No notes') }}</p>
        </div>

        <!-- Order summary -->

        <div class="mt-8">
          <h3 class="font-xl ff-bold dark-gray-font mb-6">{{ $t("Summary") }}</h3>
          <div class="d-flex justify-space-between ff-regular dark-gray-font font-md mb-4">
            <div>{{ $t("sub total") }}</div>
            <div>
              {{ order.cost.sub_total }} {{ order.cost.currency }}
            </div>
          </div>
          <div v-if="order.cost.discount"
               class="d-flex justify-space-between ff-regular dark-gray-font font-md mb-4 color-red">
            <div>{{ $t("discount") }}</div>
            <div>
              - {{ order.cost.discount }} {{ order.cost.currency }}
            </div>
          </div>

          <div class="d-flex justify-space-between ff-regular dark-gray-font font-md mb-4">
            <div>{{ $t("Delivery") }}</div>
            <div>
              {{ order.cost.delivery_fees }} {{ order.cost.currency }}
            </div>
          </div>

          <div class="d-flex justify-space-between ff-regular dark-gray-font font-md mb-4">
            <div>{{ $t("Tax") }}</div>
            <div>
              {{ order.cost.tax }} {{ order.cost.currency }}
            </div>
          </div>


          <v-divider class="d-block my-8 d-md-none"></v-divider>

          <div class="d-flex justify-space-between ff-bold dark-gray-font font-xl mb-0 align-center">
            <div>{{ $t("total") }}</div>
            <div class="ff-medium dark-gray-font font-lg">
              {{ order.cost.total }} {{ order.cost.currency }}
            </div>
          </div>
          <!-- <div v-if="order.cost.discount" class="d-flex justify-space-between ff-bold  font-xl mb-4 text-decoration-line-through" style="color: var(--main-color);">
              <div></div>
              <div class="ff-medium  font-lg">
                  {{order.cost.sub_total + order.cost.delivery_fees + ((order.cost.sub_total + order.cost.delivery_fees)* 0.14) }} {{order.cost.currency}}
              </div>
          </div> -->

        </div>

        <v-row class="btn-container d-flex justify-space-between mt-6 mt-md-12 mx-0">
          <v-btn id="checkout" depressed
                 :color="mainColor"
                 dark
                 class="ff-bold"
                 @click="updateOrder"
          >{{ $t('Confirm order') }}
          </v-btn>
          <v-btn id="checkout" class="cancel-btn ff-bold" :color="mainColor" outlined
                 @click="cancelBooking"
          >{{ $t('cancel order') }}
          </v-btn>

        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios';
import locationPicker from '@/views/ordering/components/map/locationPicker';
import AddedOrder from "./components/order-listing/components/added-order";
import shared from "@/views/ordering/shared";

export default {
  name: 'locationTestView',
  data() {
    return {
      dialog: false,
      error_msg: '',
    }
  },
  components: {
    AddedOrder,
    // eslint-disable-next-line vue/no-unused-components
    locationPicker,
  },
  mounted() {
    let title = document.getElementsByTagName('title')[0];
    title.innerText = this.header;
    let faviconLink = document.getElementById('favicon');
    faviconLink.setAttribute('href', this.logo);
    faviconLink.setAttribute('type', 'image/png');
  },
  methods: {
    async cancelBooking() {
      await this.$store.dispatch('setLoader', true)
      try {
        const res = await this.$http_gateway.post('/cancel_all_dialogs', {
          identifierId:
              this.channel === 'whatsapp'
                  ? this.channelData.WARedirectNo
                  : this.channel === 'facebook'
                      ? this.channelData.FBRedirectPageNo
                      : this.channel == 'twitter'
                          ? this.channelData.TWRedirectPageNo
                          : '',
          channel: this.channel,
          userId: this.$route.query.user_id,
          botId: this.$route.query.bot_id,
          cancelRedirectBlock: this.cancelRedirectBlock || process.env.VUE_APP_BEENA_CANCEL_REDIRECTION_BLOCK,
          slug: 'order'
        })
        await this.cancel()
      } catch (e) {
        console.log(e)
      } finally {
        await this.$store.dispatch('setLoader', false)
      }
    },
    cancel() {
      const order = this.$store.state.order_ext.order_id;

      axios
          .post(
              `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/cancel/${order} `
          )
          .then((res) => {
            localStorage.clear()
            if (this.channel === "whatsapp")
              window.location.replace(
                  `https://wa.me/${this.channelData.WARedirectNo}`
              );
            else if (this.channel === "facebook")
              window.location.replace(
                  `http://m.me/${this.channelData.FBRedirectPageNo}`
              );
            else if (this.channel == "twitter")
              window.location.replace(
                  `https://twitter.com/messages/${this.channelData.TWRedirectPageNo}`
              );
          });
    },
    confirmOrder() {

      axios
          .post(process.env.VUE_APP_API_ROOT_BEENA, {
            channel: this.channel,
            user_id: this.$route.query.user_id,
            bot_id: this.$route.query.bot_id,
            text: JSON.stringify({
              order_details: this.basket.map((e) => `${e.quantity} ${e.name}`),
              total: this.paymentDetails.total + this.paymentDetails.currency,
              notes: this.notes,
            }),
          })

          .catch((e) => {
            this.dialog = true
            this.error_msg = e.response.data.message
          });

    },

    updateOrder: function () {
      if (this.paymentDetails && this.paymentDetails.total) {
        const order_id = this.$store.state.order_ext.order_id;

        let toBeSentOrder = shared.createUpdatedOrder(this.order, this.address.address_id, this.notes, this.basket);
        toBeSentOrder.status = 'confirmed'
        console.log("toBeSentOrder items", toBeSentOrder.items);

        axios.put(`${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/update/${order_id}?include=address,discount,meals,meals.mealsAttributeSets`, toBeSentOrder)
            .then(() => this.confirmOrder())
            .then(() => {
              this.$store.dispatch('resetMapState')
              localStorage.clear()
            })
            .then(() => {
              console.log('order confirmed')
              this.$router.push({path: '/order/order-confirmed', query: {...this.$route.query}})
            })
            .catch((e) => {
              this.dialog = true
              this.error_msg = e.response.data.errors[0].detail
            })
      }
    },

  },
  computed: {
    cancelRedirectBlock: {
      get: function () {
        return this.$store.state.order_ext.cancelRedirectBlock;
      },
      set: function (value) {
        this.$store.commit('updateCancelRedirectBlock', value);
      },
    },
    header: {
      get: function () {
        return this.$store.state.order_ext.header;
      },
      set: function (value) {
        this.$store.commit('updateHeader', value);
      },
    },
    logo: {
      get() {
        return this.$store.state.order_ext.logo;
      },
      set(value) {
        this.$store.commit('updateLogo', value);
      },
    },

    meals: {
      get() {
        return this.$store.state.order_ext.meals;
      },
      set(value) {
        this.$store.commit('updateMeals', value);
      },
    },
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
    userStyle() {
      return {
        "--main-color": this.mainColor,
      };
    },
    channel: {
      get() {
        return this.$store.state.order_ext.channel;
      },
      set(value) {
        this.$store.commit("updateChannel", value);
      },
    },
    channelData: {
      get() {
        return this.$store.state.order_ext.channelData;
      },
      set(value) {
        this.$store.commit("updateChannelData", value);
      },
    },
    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit("updateBasket", value);
      },
    },
    notes: {
      get() {
        return this.$store.state.order_ext.notes;
      },
      set(value) {
        this.$store.commit("updateNotes", value);
      },
    },
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },

  }
};
</script>

<style lang="scss">
@import './orderSummery.scss';
</style>
