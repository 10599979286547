<template>
  <v-container>
    <v-row class="not-found-container">
      <v-col cols="12" md="9" sm="8" class="not-found">
        <h1 class="not-found-message">404</h1>
        <h3 class="message">Not Found</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "not-found",
  data: () => ({
    mainColor: null,
  }),
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "./not-found.scss";
</style>
