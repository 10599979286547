import i18n from '../../i18n';
i18n.locale = localStorage.getItem('locale');

const getDefaultState = () => {
  return {
    logo: '',
    mainColor: '',
    channel: '',
    notes: '',
    channelData: {},
    header: '',
    description: '',
    requestSubmitOrder: null,
    paymentDetails: [],
    basket: [],
    meals: [],
    order: {},
    calculating: false,
    order_id: '',
    mobile_no: '',
    cancelRedirectBlock: 0,
    discount_code: {},
    overlay: {
      error: false,
      error_msg: false,
    },
    overlayMealDetails: {
      dialog: false,
      data: {},
    },
  };
};
const getDefaultOverlayDetails = () => {
  return {
    dialog: false,
    showErr: false,
    data: {
      id: '',
      quantity: 0,
      currency: '',
      name: '',
      description: '',
      price: '',
      image: '',
      mealsAttributeSets: [
        {
          title: '',
          is_required: true,
          min: 1,
          max: 1,
          type: '',
          mealAttributeSetOptions: {
            data: [{ title: '', id: '', price: '' }],
          },
        },
      ],
    },
  };
};

export const order_ext = {
  state: {
    logo: '',

    mainColor: '',
    channel: '',
    notes: '',
    channelData: {},
    header: '',
    description: '',
    requestSubmitOrder: null,
    paymentDetails: [],
    basket: [],
    meals: [],
    order: {},
    calculating: false,
    order_id: '',
    mobile_no: '',
    cancelRedirectBlock: 0,
    discount_code: {},
    overlay: {
      error: false,
      error_msg: false,
    },
    overlayMealDetails: {
      dialog: false,
      showErr: false,
      data: {
        id: '',
        quantity: 0,
        currency: '',
        name: '',
        description: '',
        price: '',
        image: '',
        mealsAttributeSets: [
          {
            title: '',
            is_required: true,
            min: 1,
            max: 1,
            type: '',
            mealAttributeSetOptions: {
              data: [{ title: '', id: '', price: '' }],
            },
          },
        ],
      },
    },
  },
  actions: {},
  mutations: {
    resetOrderState(state) {
      Object.assign(state, getDefaultState());
    },
    resetOverlayMealDetails(state) {
      Object.assign(state.overlayMealDetails, getDefaultOverlayDetails());
    },
    updateOverlayMealDetails(state, value) {
      state.overlayMealDetails = value;
    },
    updateMobileNo(state, value) {
      state.mobile_no = value;
    },
    updateOrderId(state, value) {
      state.order_id = value;
    },
    updateMeals(state, value) {
      state.meals = value;
    },
    updateBasket(state, value) {
      state.basket = value;
    },
    updatePaymentDetails(state, value) {
      state.paymentDetails = value;
    },
    updateHeader(state, value) {
      state.header = value;
    },
    updateDescription(state, value) {
      state.description = value;
    },
    updateChannel(state, value) {
      state.channel = value;
    },
    updateChannelData(state, value) {
      state.channelData = value;
    },
    updateRequestSubmitOrder(state, value) {
      state.requestSubmitOrder = value;
    },
    updateMainColor(state, value) {
      state.mainColor = value;
    },
    updateLogo(state, value) {
      state.logo = value;
    },

    updateOrder(state, value) {
      state.order = value;
    },

    updateCalculating(state, value) {
      state.calculating = value;
    },
    updateNotes(state, value) {
      state.notes = value;
    },
    updateCancelRedirectBlock(state, value) {
      state.cancelRedirectBlock = value;
    },
    updateDiscountCode(state, value) {
      state.discount_code = value;
    },
    updateOverlay(state, value) {
      state.overlay = value;
    },
  },
};
