<template>
  <div :class="`empty-cart-container ${$i18n.locale}`">
    <h3 :style="{ direction: $i18n.locale == 'ar' ? 'rtl' : 'ltr' }">
      {{ $t('my order') }}
    </h3>
    <div class="img-container">
      <img
        v-if="$i18n.locale == 'en'"
        src="../../../../../assets/img/Empty Cart Artwork@2x.png"
        alt="empty cart"
      />
      <img
        v-else
        src="../../../../../assets/img/No Orders@2x.png"
        alt="empty cart"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '../../../styles/empty-cart.scss';
</style>
