<template>
  <div class="allowed-times-container">
    <h3 class="time-slots">CHOOSE TIME SLOT</h3>
    <v-divider class="inner-divider"></v-divider>
    <ul class="donate-now" v-if="selectedDateOBJ">
      <li v-for="(item, index) in selectedDateOBJ.availableSlots" :key="index">
        <input
          type="radio"
          :id="item.time"
          name="time"
          v-model="time"
          :value="item.time"
        />
        <label :for="item.time">{{ item.time }}</label>
      </li>
    </ul>

    <v-divider class="inner-divider"></v-divider>
    <div class="btn-container">
      <button
        :class="{ disabled: !currentDate || !time, create: true }"
        :disabled="!currentDate || !time"
        @click="create"
      >
        DONE
      </button>
    </div>
    <div class="btn-container">
      <button class="cancel" @click="cancel">{{ cancelButtonLabel }}</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "allowed-times",
  props: [
    "dates",
    "currentDate",
    "WARedirectNo",
    "FBRedirectPageNo",
    "TWRedirectPageNo",
    "channel",
    "cancelButtonLabel",
    "cancelRedirectBlock",
  ],

  components: {},

  data: () => ({
    times: null,
    time: null,
  }),
  methods: {
    create() {
      axios
        .post(`${process.env.VUE_APP_API_ROOT_CALENDAR}/extended-message?`, {
          channel: this.channel,
          user_id: this.$route.query.user_id,
          bot_id: this.$route.query.bot_id,
          text: this.currentDate + " " + this.time,
        })
        .then((res) => {
          console.log(res);
          if (this.channel === "whatsapp")
            window.location.replace(`https://wa.me/${this.WARedirectNo}`);
          else if (this.channel === "facebook")
            window.location.replace(
              `https://www.facebook.com/messages/t/${this.FBRedirectPageNo}`
            );
          else if (this.channel == "twitter")
            window.location.replace(
              `https://twitter.com/messages/${this.TWRedirectPageNo}`
            );
        })
        .catch((e) => console.log(e));
    },
    cancel() {
      axios
        .post(`${process.env.VUE_APP_API_ROOT_CALENDAR}/cancel_all_dialogs`, {
          channel: this.channel,
          userId: this.$route.query.user_id,
          botId: this.$route.query.bot_id,
          cancelRedirectBlock: this.cancelRedirectBlock,
        })
        .then((res) => {
          console.log(res);
          if (this.channel === "whatsapp")
            window.location.replace(`https://wa.me/${this.WARedirectNo}`);
          else if (this.channel === "facebook")
            window.location.replace(
              `https://www.facebook.com/messages/t/${this.FBRedirectPageNo}`
            );
          else if (this.channel == "twitter")
            window.location.replace(
              `https://twitter.com/messages/${this.TWRedirectPageNo}`
            );
        })
        .catch((e) => console.log(e));
    },
  },

  computed: {
    selectedDateOBJ: function () {
      return this.dates.find((e) => e.date === this.currentDate);
    },
  },
  watch: {
    currentDate: function () {
      this.time = null;
    },
  },
};
</script>
<style lang="scss">
@import "../style/allowed-times.scss";
</style>
