<template>
  <div>
    <div class="date-picker-upper-text-container">
      <div class="img-container"><img :src="logo" alt="logo" /></div>
      <div class="date-picker-upper-text">
        <h3>{{ header }}</h3>
        <p>
          {{ desc }}
        </p>
      </div>
    </div>
    <v-date-picker
      :weekday-format="getDay"
      v-model="picker"
      full-width
      first-day-of-week="-1"
      show-adjacent-months
      :allowed-dates="getAllowedDates"
      :color="mainColor"
      ref="date-picker-ref"
      @input="updateDate"
    ></v-date-picker>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "date-picker",

  components: {},
  props: [
    "availableDates",
    "currentDate",
    "header",
    "desc",
    "mainColor",
    "logo",
  ],

  data: () => ({
    picker: null,
  }),

  methods: {
    getDay(date) {
      const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getAllowedDates(val) {
      return this.availableDates.indexOf(val) !== -1;
    },
    updateDate() {
      this.$emit("input", this.picker);
    },
  },
  watch: {
    currentDate: function () {
      this.picker = this.currentDate;
    },
  },
};
</script>
<style lang="scss">
@import "../style/date-picker.scss";
</style>
