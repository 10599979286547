import Calendar from './views/calendar';
import OrderExt from './views/ordering';
import AddLocation from './views/ordering/addLocation.vue';
import OrderSummery from './views/ordering/orderSummery.vue';
import OrderConfirmed from './views/ordering/OrderConfirmed.vue';

import NotFound from './errors/not-found.vue';
import BookingSteps from '@/views/bookingStep/BookingSteps';

export const routes = [
  { path: '/calender-booking', component: Calendar },
  { path: '/order', component: AddLocation },
  { path: '/order/ext', component: OrderExt },
  { path: '/order/summery', component: OrderSummery },
  { path: '/order/order-confirmed', component: OrderConfirmed },

  { path: '*', component: NotFound },
  { path: '/book-appointment', component: BookingSteps },
];
