<template>
  <div class="order-listing-web">
    <div v-if="!basket.length">
      <empty-cart />
    </div>
    <div v-else>
      <cart-with-orders />
    </div>
  </div>
</template>

<script>
import EmptyCart from './components/empty-cart.vue';
import CartWithOrders from './components/cart-with-orders.vue';
export default {
  components: {
    EmptyCart,
    CartWithOrders,
  },
  computed: {
    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/order-listing-web.scss';
</style>
