<template>
  <div class="order-payment-details">
    <v-divider class="mt-2 mb-5"></v-divider>
    <div>
      <div class="addCommentParent mt-4">
        <span>{{ $t('voucher_label') }}</span>
      </div>
      <div
        class="comment-container d-flex align-start justify-space-between mb-0"
      >
        <v-text-field
          outlined
          color="light-gray"
          name="input-7-4"
          @input="inputHandler"
          @keydown.enter.prevent="validateCode"
          :value="code"
          dense
          :placeholder="$t('voucher_placeholder')"
          :error="error"
          :error-messages="error_msg"
          :disabled="has_discount"
        ></v-text-field>
        <v-btn
          :disabled="!code || has_discount"
          @click="validateCode"
          depressed
          :style="{
            'font-family': $i18n.locale == 'ar' ? 'Tag-Bold' : 'Poppins-Bold',
            'font-size': '12px !important',
            height: '40px',
            background: 'var(--main-color)',
            color: 'white',
          }"
          >{{ $t('voucher_btn') }}</v-btn
        >
      </div>
    </div>
    <div class="detail">
      <div>{{ $t('sub total') }}</div>
      <div class="cost">
        <div>{{ details.currency }}</div>
        <div>
          {{ details.sub_total }}
        </div>
      </div>
    </div>
    <div class="detail color-red" v-if="details.discount">
      <div>{{ $t('discount') }}</div>
      <div class="cost">
        <div>{{ details.currency }}</div>
        <div>
          - {{ details.discount }}
        </div>
      </div>
    </div>
    <div class="detail">
      <div>{{ $t('Delivery') }}</div>
      <div class="cost">
        <div>{{ details.currency }}</div>
        <div>
          {{ details.delivery_fees }}
        </div>
      </div>
    </div>
    <div class="detail">
      <div>{{ $t('Tax') }}</div>
      <div class="cost">
        <div>{{ details.currency }}</div>
        <div>
          {{ details.tax }}
        </div>
      </div>
    </div>

    <v-divider class="my-2"></v-divider>
    <div class="total">
      <div>{{ $t('total') }}</div>
      <div class="cost">
        <div>{{ details.currency }}</div>
        <div>
          {{ details.total }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: [
    'details',
    'validateCode',
    'code',
    'error',
    'error_msg',
    'has_discount',
  ],
  methods: {
    inputHandler(value) {
      this.$emit('codeAdded', value);
    },
  },
  mounted() {
   // console.log('details', this.details);
  },
};
</script>

<style lang="scss">
@import '../../../styles/order-payment-details.scss';
</style>
