<template>
  <div>
    <v-container class="pb-10" style="max-width: 1200px">
      <h2 class="text-secondary mb-4 text-uppercase font-weight-bold">{{$store.state.bookingSteps.stepsData.step3.title}}</h2>

        <v-row>
          <v-col cols="12" md="8">
<!--            <allowed-dates v-if="dates.length" :available-dates="dates.map((e) => e.date)" @input="getTimes" />-->
            <allowed-dates  @input="getTimes" />
          </v-col>
          <v-col cols="12" md="4">
            <div class="d-flex align-center justify-center "  v-show="loading">
              <v-progress-circular
                  indeterminate
                  class="time-loading"
                  size="80"
                  color="primary"
                  v-show="loading"
              ></v-progress-circular>
            </div>

            <allowed-times :available-slots="allowedTimesSlots" :selected-date="selectedData" @changeTime="selectedTime = $event" v-show="!loading"  />
          </v-col>
        </v-row>

    </v-container>

    <div class="step-nav">
      <div class="d-flex align-center">
        <v-btn outlined  class="mx-5" color="secondary"  @click="$emit('cancel')">
          {{$store.state.bookingSteps.stepsData.cancelButtonLabel}}
        </v-btn>

        <v-btn  color="primary" class="mx-5"  :disabled="!selectedTime || !selectedTime" @click="book" elevation="0">
          {{$store.state.bookingSteps.stepsData.bookingButtonLabel}}
        </v-btn>
      </div>
    </div>
  </div>

</template>

<script>
import allowedDates from "./allowedDates";
import allowedTimes from "./allowedTimes";
import API from "@/api/dynamicAPI";
import moment from "moment";
export default {
  name: "step3",
  components: {
    allowedTimes,
    allowedDates
  },
  data() {
    return {
      selected: null,
      allowedTimesSlots: [],
      dates: [],
      loading: false,
      selectedData: null,
      selectedTime: null
    }
  },
  async mounted() {

  },
  methods: {
    async getTimes(date) {
      this.allowedTimesSlots = []
      this.selectedData = date
      try {
        this.loading = true
        let extraAttributes = {
          "specialty_id": this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step1.selectedIdAttribute],
          "clinic_code": this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step2.selectedCodeAttribute],
          "doctor_id": this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step2.selectedIdAttribute],
          "date": date
        }
        let res = ""
        if (this.$store.state.bookingSteps.stepsData.step3.request.type === 'get') {
          res = await API.getAPI(this.$store.state.bookingSteps.stepsData.step3.request , this.$store.state.bookingSteps.stepsData.step3.request.headers ,extraAttributes)
        }
        else if (this.$store.state.bookingSteps.stepsData.step3.request.type === 'post') {
          res = await API.postAPI(this.$store.state.bookingSteps.stepsData.step3.request , this.$store.state.bookingSteps.stepsData.step3.request.headers, extraAttributes)
        }
        if (res.data.length) {
          this.allowedTimesSlots = res.data[0].availableSlots
        }
      } catch (e) {

      } finally {
        this.loading = false
      }

      this.loading = false
    },
    book() {
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step3.selectedDateAttribute] = this.selectedData
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step3.selectedTimeAttribute] = this.selectedTime
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step3.selectedWorkDayAttribute] = this.$t(`weekDays.${moment(this.selectedData).isoWeekday()}`)
      this.$emit('submitBooking')
    }
  }
}
</script>

<style scoped>
@media (min-width: 960px) {
  .time-loading {
    margin-top: 150px;
  }
}
</style>
