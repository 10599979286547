<template>
  <v-dialog
    v-model="value"
    max-width="700"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :class="$i18n.locale"
  >
    <v-card>
      <div :class="$i18n.locale + ' pa-5'">
        <v-btn
          :color="mainColor"
          icon
          text
          class="hidden-sm-and-down"
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div :class="'back-arrow-container-'+$i18n.locale+ ' hidden-md-and-up'" v-if="step == 1">
          <v-icon :color="mainColor" @click="$emit('input', false)"
            >mdi-arrow-right</v-icon
          >
        </div>

        <div class="px-md-10 pb-8">
          <v-stepper v-model="step">
            <v-stepper-items>
              <v-stepper-content step="1">
                <h2 class="text-capitalize ff-bold">
                  {{ $t('complete checkout') }}
                </h2>
                <p class="ff-regular">
                  {{ $t('Please complete the following to complete checkout') }}
                </p>
                <v-form ref="checkoutForm" class="px-md-10 py-2">
                  <label for="" class="text-capitalize ff-regular">{{
                    $t('full name')
                  }}</label>
                  <v-text-field
                    v-model="formObj.name"
                    outlined
                    validate-on-blur
                    class="ff-regular"
                    dense
                    :rules="[(v) => !!v || $t('This field is required')]"
                  />
                  <label for="" class="text-capitalize ff-regular">{{
                    $t('phone')
                  }}</label>
                  <v-text-field
                    v-model="formObj.phone"
                    outlined
                    class="ff-regular"
                    dense
                    type="number"
                    validate-on-blur
                    :rules="[
                      (v) => !!v || $t('This field is required'),
                      (v) =>
                        v.length === 11 ||
                        $t('Phone number should be 11 digit'),
                    ]"
                  >
                  </v-text-field>

                  <p
                    v-if="errorFromSubmitOtp"
                    :style="{
                      color: 'red',
                      'font-family':
                        $i18n.locale == 'ar'
                          ? 'Tag-Regular'
                          : 'Poppins-Regular',
                    }"
                  >
                    {{ errorFromSubmitOtp }}
                  </p>

                  <div class="text-center">
                    <v-btn
                      @click="submitForm"
                      :color="mainColor"
                      dark
                      depressed
                      width="220"
                      class="text-capitalize ff-bold"
                      >{{ $t('next') }}</v-btn
                    >
                  </div>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <h2 class="text-capitalize ff-bold">
                  {{ $t('Verification') }}
                </h2>
                <p class="ff-regular">
                  {{ $t('Enter 4 digit code sent to') }} {{ formObj.phone }}
                </p>
                <otp-input
                  v-if="step == 2"
                  @input="handleOtp"
                  @complete="handleOtp"
                  @cleared="clearOtp = false"
                  :clearOtp="clearOtp"
                  :error="otpError"
                />
                <p
                  v-if="otpError"
                  class="text-center mt-3 mb-0 ff-regular"
                  style="color: red"
                >
                  {{ $t('Wrong code') }}
                </p>
                <p class="my-3 font-md ff-regular">
                  {{ $t('Not get code') }}
                  <span v-if="countDown > 0" class="ff-bold"
                    >{{ countResend }}:{{ countDown }}</span
                  >
                  <span v-else class="text-decoration-underline ff-bold">
                    <a @click.prevent="resendCode" class="black--text">{{
                      $t('Send again')
                    }}</a>
                  </span>
                </p>
                <p class="my-3 font-md ff-regular" v-if="!(countDown > 0)">
                  <span
                    class="cursor-pointer text-decoration-underline"
                    @click="toPrevStep"
                  >
                    {{ $t('Edit phone number ?') }}
                  </span>
                </p>
                <div class="text-center">
                  <v-btn
                    dark
                    @click="submitOtp"
                    :color="mainColor"
                    width="220"
                    depressed
                    class="text-capitalize mt-7 ff-bold"
                    >{{ $t('next') }}</v-btn
                  >
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import otpInput from '@/views/ordering/components/checkout/otpInput';
import axios from 'axios';

export default {
  name: 'checkoutDialog',
  props: ['value'],
  components: {
    otpInput,
  },
  data() {
    return {
      step: 1,
      formObj: {
        name: '',
        phone: '',
      },
      otpCode: '',
      otpError: false,
      resend: 0,
      countResend: 0,
      countDown: 5,
      clearOtp: false,
      errorFromSubmitOtp: '',
    };
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
  mounted() {
    this.formObj.name = this.order.client.full_name;
    this.formObj.phone = this.order.client.mobile
      ? this.order.client.mobile
      : '';
  },
  methods: {
    submitForm() {
      if (this.$refs.checkoutForm.validate()) {
        axios
          .post(
            `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/send-otp`,
            {
              name: this.formObj.name,
              mobile: this.formObj.phone,
              order_id: this.order.id,
            }
          )
          .then(() => {
            this.step++;
            this.countDown = 59;
            this.countDownTimer();
            this.otpError = false;
            this.errorFromSubmitOtp = '';
          })
          .catch((e) => {
            this.errorFromSubmitOtp = e.response.data.errors[0].detail;
          });
      }
    },
    handleOtp(e) {
      this.otpError = false;
      this.otpCode = e;
    },
    submitOtp() {
      axios
        .post(
          `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/check-otp`,
          {
            code: this.otpCode,
            mobile: this.formObj.phone,
            order_id: this.order.id,
          }
        )
        .then(() => {
          this.$router.push({
            path: '/order/summery',
            query: { ...this.$route.query },
          });
        })
        .catch(() => {
          this.otpError = true;
        });
    },
    toPrevStep() {
      if (this.step == 1) {
        clearTimeout();
      }
      this.countDown = 0;
      this.step--;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        if (this.countResend > 0) {
          this.countResend--;
          this.countDown = 59;
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      }
    },
    resendCode() {
      if (this.resend < 2) {
        this.resend++;
      } else this.resend = 9;
      this.countResend = this.resend;
      this.countDown = 59;
      axios
        .post(
          `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/send-otp`,
          {
            name: this.formObj.name,
            mobile: this.formObj.phone,
            order_id: this.order.id,
          }
        )
        .then(() => {
          this.countDownTimer();
          this.clearOtp = true;
        });
    },
  },
  computed: {
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.back-arrow-container-ar{
  position: relative;
  height: 5px;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  .v-icon {
    position: absolute;
    left: 0;
    top: 25px;
  }
}

.back-arrow-container-en{
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 9999;
  display: flex;
  justify-content: flex-start;
  .v-icon {
    position: absolute;
    right: 0;
    top: 25px;
  }
}

::v-deep .v-stepper__wrapper {
  border-top: none !important;
}
::v-deep .v-input__prepend-inner {
  margin-top: 12px !important;
}
.ar {
  direction: rtl !important;
  text-align: right !important;
  .v-icon {
    transform: rotate(180deg);
  }

  ::v-deep .v-messages__message {
    text-align: right !important;
    direction: rtl !important;
  }
}
</style>
