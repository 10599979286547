import Vue from 'vue'
const snackbarDefaults = {
  text: 'New Snackbar Alert',
  color: 'primary',
  status: false,
  lang: 'en'
}
export const core = {
  state: {
    savingStatus: false,
    loader: false,
    errorMsg: false,
    snackbar: { ...snackbarDefaults },
    clientStyle: {},
  },
  mutations: {
    'SET_SAVING_STATUS' (state, savingStatus) {
      state.savingStatus = savingStatus
    },
    SET_LOADER(state,loader) {
      state.loader = loader
    },
    SET_ERROR(state,errorMsg) {
      state.errorMsg = errorMsg
    },
    SET_SNACKBAR(state, options) {
      state.snackbar = options
      state.snackbar.status = true
    },
    SET_CLIENT_STYLE (state, clientStyle) {
      state.clientStyle = clientStyle
    },
    SET_LANG (state, lang) {
      state.lang = lang
    }

  },
  actions: {
    setSnackbar({commit}, options) {
      commit('SET_SNACKBAR', { ...snackbarDefaults, ...options })
    },
    setSavingStatus ({commit} , savingStatus) {
      commit('SET_SAVING_STATUS', savingStatus)
    },
    setLoader({commit},loader) {
      commit('SET_LOADER', loader)
    },
    setErrorMessage ({commit},errorMsg) {
      commit('SET_ERROR', errorMsg)
    },
    async setClientStyle ({commit},data) {
      try {
        let lang = 'en_EN'
        if (data.lang === 'ar') {
          lang = 'ar_AR'
        }
        let res = await Vue.prototype.$http_session.get(`bots/${data.botId}/widget-settings?lang=${lang}`)
        commit('SET_CLIENT_STYLE', res.data.data)
      }
      catch (e) {

      }
    },
    setLang ({commit}, lang) {
      commit('SET_LANG', lang)
    }
  }
}
