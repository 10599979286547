<template>
  <v-container fluid :style="userStyle" :class="$i18n.locale">
    <div style="z-index: 99999999999999999">
      <v-overlay
        :value="overlay.error"
        class="d-flex justify-center"
        style="z-index: 555"
      >
        <div class="overlay-container pa-5">
          <div class="close-btn-container mb-1">
            <v-icon size="25" class="cursor-pointer" @click="closeOverlay">
              mdi-close</v-icon
            >
          </div>
          <h4 class="ff-bold font-xl dark-gray-font mb-4 px-2 ff-bold">
            {{ $t('We are sorry') }}
          </h4>
          <p class="ff-medium font-md dark-gray-font mb-7 px-2 ff-regular">
            {{ overlay.error_msg }}
          </p>
        </div>
      </v-overlay>
    </div>
    <v-row justify="center">
      <v-col cols="12" md="8" sm="12" xs="12" class="pb-0 correction"> </v-col>
      <v-col cols="12" md="8" sm="12" xs="12" class="pb-0 left-col">
        <card-listing :branches="branches" />
      </v-col>

      <v-col cols="12" md="4" sm="4" xs="4" class="web right-col">
        <div style="width: 100%; text-wrap: wrap">
          <order-listing-web />
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="4" xs="4" class="mob">
        <div style="width: 100%; text-wrap: wrap">
          <order-listing-mob />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CardListing from './components/card-listing/card-lisiting.vue';
import OrderListingWeb from './components/order-listing/order-listing-web.vue';
import OrderListingMob from './components/order-listing/order-listing-mob.vue';

export default {
  components: {
    CardListing,
    OrderListingWeb,
    OrderListingMob,
  },
  data() {
    return {
      main_color: '',
      loading: true,
      branches: [],
    };
  },
  mounted() {
    let title = document.getElementsByTagName('title')[0];
    title.innerText = this.header;
    let faviconLink = document.getElementById('favicon');
    faviconLink.setAttribute('href', this.logo);
    faviconLink.setAttribute('type', 'image/png');
    this.closeOverlay();
    this.$store.commit('resetOverlayMealDetails');
  },

  methods: {
    closeOverlay() {
      this.overlay.error = false;
      this.overlay.error_msg = '';
    },
  },
  computed: {
    header: {
      get: function () {
        return this.$store.state.order_ext.header;
      },
      set: function (value) {
        this.$store.commit('updateHeader', value);
      },
    },
    overlay: {
      get: function () {
        return this.$store.state.order_ext.overlay;
      },
      set: function (value) {
        this.$store.commit('updateOverlay', value);
      },
    },

    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
    logo: {
      get() {
        return this.$store.state.order_ext.logo;
      },
      set(value) {
        this.$store.commit('updateLogo', value);
      },
    },

    userStyle() {
      return {
        '--main-color': this.mainColor,
      };
    },
  },
};
</script>
<style lang="scss">
@import './index.scss';
</style>
