<template>
  <div :class="'cart-with-orders ' + this.$i18n.locale" :style="userStyle">
    <h3>{{ $t('my order') }}</h3>
    <div class="orders-container">
      <div v-for="(order, index) in basket" :key="index">
        <added-order
          :title="order.name"
          :options="order.mealsAttributeSets? [...order.mealsAttributeSets] : (order.mealAttributeSets? order.mealAttributeSets.data : [])"          :index="index"
          :id="order.id"
          :desc="order.description"
          :count="order.quantity"
          :price="order.price"
          :item="order"
          :currency="order.currency"
          :comment="order.comment"
        />
      </div>
    </div>
    <order-payment-details
      @codeAdded="changeCode"
      :code="code"
      :has_discount="order.has_discount"
      :error="error"
      :error_msg="error_msg"
      :validateCode="validateCode"
      :details="paymentDetails"
      v-if="!calculating && paymentDetails && paymentDetails.total"
    />
    <div class="loader-container" v-if="calculating" ref="loading">
      <v-progress-circular
        :size="35"
        :width="3"
        color="#afafaf"
        indeterminate
        id="loading"
      />
    </div>
    <div class="comment-container">
      <div class="notesLabel">
        {{ $t('notes') }}
      </div>
      <v-textarea
        outlined
        no-resize
        rows="3"
        :color="mainColor"
        :placeholder="$t('notes_placeholder')"
        name="input-7-4"
        v-model="notes"
      ></v-textarea>
    </div>
    <div class="btn-container d-flex justify-center">
      <v-btn id="checkout" depressed @click="submitOtp"
        >{{ $t('checkout') }}
      </v-btn>
      <v-btn
        id="checkout"
        class="cancel-btn"
        :color="mainColor"
        outlined
        @click="cancelBooking"
        >{{ $t('cancel order') }}
      </v-btn>
      <checkout-dialog v-model="dialog" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import checkoutDialog from '@/views/ordering/components/checkout/checkoutDialog';
import AddedOrder from './added-order.vue';
import OrderPaymentDetails from './order-payment-details.vue';
import { Formatter } from 'sarala-json-api-data-formatter';
const formatter = new Formatter();
export default {
  components: {
    checkoutDialog,
    AddedOrder,
    OrderPaymentDetails,
  },
  data: () => ({
    loading: false,
    dialog: false,
    code: '',
    error: false,
    error_msg: '',
  }),
  mounted() {
    this.code = this.order.discount ? this.order.discount.data.code : '';
  },
  methods: {
    submitOtp() {
      if (this.order.client.full_name && this.order.client.mobile)
        this.$router.push({
          path: '/order/summery',
          query: { ...this.$route.query },
        });
      else {
        this.dialog = true;
      }
    },
    async cancelBooking() {
      await this.$store.dispatch('setLoader', true);
      try {
        const res = await this.$http_gateway.post('/cancel_all_dialogs', {
          identifierId:
            this.channel === 'whatsapp'
              ? this.channelData.WARedirectNo
              : this.channel === 'facebook'
              ? this.channelData.FBRedirectPageNo
              : this.channel == 'twitter'
              ? this.channelData.TWRedirectPageNo
              : '',
          channel: this.channel,
          userId: this.$route.query.user_id,
          botId: this.$route.query.bot_id,
          cancelRedirectBlock:
            this.cancelRedirectBlock ||
            process.env.VUE_APP_BEENA_CANCEL_REDIRECTION_BLOCK,
          slug: 'order',
        });
        await this.cancel();
      } catch (e) {
        console.log('error', e);
      } finally {
        await this.$store.dispatch('setLoader', false);
      }
    },
    changeCode(value) {
      this.code = value;
      this.error = false;
      this.error_msg = '';
    },
    validateCode() {
      if (!this.code) return;
      if (this.order.has_discount) return;

      this.calculating = true;

      axios
        .post(
          `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/apply-code/${this.order_id}?include=address,discount`,
          { code: this.code }
        )
        .then((res) => {
          let data = JSON.parse(
            JSON.stringify(formatter.deserialize(res.data))
          );
          this.error = false;
          this.error_msg = '';
          this.order = { ...data };
          this.paymentDetails = data.cost;
        })
        .catch((err) => {
          this.error = true;
          this.error_msg = err.response.data.errors[0].detail;
        })
        .finally(() => (this.calculating = false));
    },
    cancel() {
      const order = this.$store.state.order_ext.order_id;
      axios
        .post(
          `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/orders/customer/cancel/${order}`
        )
        .then((res) => {
          if (this.channel === 'whatsapp')
            window.location.replace(
              `https://wa.me/${this.channelData.WARedirectNo}`
            );
          else if (this.channel === 'facebook')
            window.location.replace(
              `http://m.me/${this.channelData.FBRedirectPageNo}`
            );
          else if (this.channel == 'twitter')
            window.location.replace(
              `https://twitter.com/messages/${this.channelData.TWRedirectPageNo}`
            );
        });
    },
  },

  computed: {
    cancelRedirectBlock: {
      get: function () {
        return this.$store.state.order_ext.cancelRedirectBlock;
      },
      set: function (value) {
        this.$store.commit('updateCancelRedirectBlock', value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },

    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },

    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    notes: {
      get() {
        return this.$store.state.order_ext.notes;
      },
      set(value) {
        this.$store.commit('updateNotes', value);
      },
    },
    channel: {
      get() {
        return this.$store.state.order_ext.channel;
      },
      set(value) {
        this.$store.commit('updateChannel', value);
      },
    },
    channelData: {
      get() {
        return this.$store.state.order_ext.channelData;
      },
      set(value) {
        this.$store.commit('updateChannelData', value);
      },
    },

    calculating: {
      get() {
        return this.$store.state.order_ext.calculating;
      },
      set(value) {
        this.$store.commit('updateCalculating', value);
      },
    },

    userStyle() {
      return {
        '--main-color': this.$store.state.order_ext.mainColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/cart-with-orders.scss';
</style>
