<template>
  <div style="position: relative" v-if="overlayMealDetails.dialog">
    <div class="overLayMealDetailsContainer">
      <v-overlay
        :value="overlayMealDetails.dialog"
        class="d-flex justify-center">
        <div class="overlay-container pa-5 pa-md-0 pt-md-4">
          <div class="close-btn-container mb-1">
            <v-icon size="25" class="cursor-pointer" @click="closeOverlay">
              mdi-close</v-icon>
          </div>

          <div class="card-listing-header">
            <div class="img-container">
              <img :src="myData.data.image" alt="logo" />
            </div>
            <div class="header-text">
              <h3>{{ myData.data.name }}</h3>

              <p class="desc">{{ myData.data.description }}</p>
              <div class="price">
                {{ myData.data.price }}
              </div>
              <div class="buttons-container">
                <v-btn
                  :disabled="myData.data.quantity === 1"
                  dark
                  color="var(--main-color)"
                  @click="removeFromMyData"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
                <div
                  class="mx-3"
                  style="
                    color: var(--main-color);
                    font-family: Tag-Bold;
                    font-size: 18px;"
                >
                  {{ myData.data.quantity }}
                </div>
                <v-btn
                  class="mx-0"
                  dark
                  color="var(--main-color)"
                  @click="addToMyData"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="expansion-container">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel expand
                  v-model="panel"
                  v-for="(item, i) in myData.data.mealsAttributeSets"
                  :key="i">
                <v-expansion-panel-header class="item-title"
                  >{{ item.title }}
                  <span
                      class="error-msg px-1"
                      v-if="item.is_required && !item.selected"
                  >({{ $t('This field is required') }})</span>
                  <span
                      class="error-msg px-1"
                      v-if="typeof item.selected == 'object' &&item.selected.length > item.max">({{ $t('Choose between ') }}{{item.min}}{{ $t(' and ') }}{{ item.max }}{{ $t(' only') }}) </span>

                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-radio-group
                    class="mt-1"
                    v-model="item.selected"
                    column
                    hide-details=""
                    v-if="item.mealsAttributeSetOption && item.min === 1 && item.max === 1">
                    <v-radio
                      v-for="(attr, ind) in item.mealsAttributeSetOption.data"
                      :key="ind"
                      :value="attr.id"
                      @change="selectRadioItem(attr.id, item, i, attr)"
                      class="my-1">
                      <template slot="label">
                        <div class="address-text">
                          <span class="address-desc">{{ attr.title }}</span>
                          <div>
                            <span class="address-desc-bold">{{attr.price }}</span>
                            <span class="address-desc">{{ myData.data.currency }}</span>
                          </div>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <div class="checkbox-container mt-3" v-else>
                    <v-checkbox
                      on-icon="mdi-checkbox-outline"
                      v-for="(attr, ind) in item.mealsAttributeSetOption.data"
                      :key="ind"
                      v-model="item.selected"
                      multiple
                      class="pt-0 my-1"
                      :color="mainColor"
                      :value="attr.id"
                      @change="selectCheckboxItems(attr.id, item, ind, attr)"
                      light
                      hide-details
                    >
                      <template slot="label">
                        <div class="address-text">
                          <span class="address-desc">{{ attr.title }}</span>
                          <div>
                            <span class="address-desc-bold">{{
                              attr.price
                            }}</span>
                            <span class="address-desc">
                              {{ myData.data.currency }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="textarea-container">
            <textarea
              class="notes-textarea"
              @blur="changeComment"
              rows="2"
              :placeholder="$t('Add Special Notes')"
            ></textarea>
          </div>
          <div class="bottom-sheet-container">
            <bottom-sheet />
          </div>
          <div class="btn-container d-flex justify-center">
            <v-btn
                id="add"
                :disabled="myData.data.valid !== true"
                depressed
                dark
                style="margin: 10px"
                :color="mainColor"
                @click="doValidation"
            >{{ $t('ADD TO CHART') }}
            </v-btn>
          </div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import BottomSheet from '../order-listing/order-listing-mob.vue';
import shared from "@/views/ordering/shared";
export default {
  components: { BottomSheet },
  data() {
    return {
      dialog: false,
      selected: null,
      myData: null,
      panel: 0
    };
  },
  created() {
    this.getItemSelectedOptions = shared.getItemSelectedOptions;
  },
  watch:{
    myData(data){
      if(data != null){
        var isValid = true;
        data.data.mealsAttributeSets.forEach(function (item) {
          if(item.is_required && !('selected' in item)){
            isValid = false;
          }else if(item.max > 1 && 'selected' in item){
            if (Array.isArray(item.selected) && item.selected.length > item.max){
              isValid = false;
            }else if (Array.isArray(item.selected) && item.selected.length < item.min){
              isValid = false;
            }
          }
        });
        if(data.data.mealsAttributeSets.length == 0){
          isValid = true;
        }
        data.data.valid = isValid;
      }
    },
    overlayMealDetails(dialog) {
      if(dialog.dialog){
        var data = JSON.parse(JSON.stringify(this.overlayMealDetails));
        //define selected by default
        data.data.mealsAttributeSets.map(function (item) {
          if(item.is_required && !('selected' in item) && item.min === item.max && item.min === 1){
            item.selected = item.mealsAttributeSetOption.data[0].id;
            item.selectedPrice = item.mealsAttributeSetOption.data[0].price;
          }
          return item;
        });
        this.myData = data;
      }else{
        this.myData = null;
      }
    },
  },
  methods: {

    addToMyData()
    {
      this.myData.data.quantity++
    },
    removeFromMyData()
    {
      this.myData.data.quantity--
    },

    changeComment(v) {
      this.myData = {
        dialog: this.myData.dialog,
        showErr: true,
        data: { comment: v.target.value, ...this.myData.data },
      };
    },
    closeOverlay() {
      this.$store.commit('resetOverlayMealDetails');
    },
    selectRadioItem(value, item, index, option) {
      item.selected = value;
      item.selectedPrice = option.price;
      this.myData = { showErr: true, ...this.myData };
    },
    selectCheckboxItems(value, item, index, option) {
      this.myData = { showErr: true, ...this.myData };
    },

    doValidation(){
      if(this.myData.data.valid){
        this.myData.dialog = false;
        this.myData.doUpdate = true;
        this.myData.data.options = this.getItemSelectedOptions(this.myData.data);
        this.overlayMealDetails = JSON.parse(JSON.stringify(this.myData));
        this.$store.commit('updateOverlayMealDetails',this.overlayMealDetails );
      }
    }
  },
  computed: {
    calculating: {
      get() {
        return this.$store.state.order_ext.calculating;
      },
      set(value) {
        this.$store.commit('updateCalculating', value);
      },
    },
    overlayMealDetails: {
      get() {
        return this.$store.state.order_ext.overlayMealDetails;
      },
      set(value) {
        this.$store.commit('updateOverlayMealDetails', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/meal-dialog-details.scss';
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--main-color) !important;
  opacity: 0.6;
  i {
    color: White !important;
  }
}
</style>
