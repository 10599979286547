<template>
  <otp-input
    ref="otpInput"
    :input-classes="`otp-input ${error && 'otp-error'}`"
    separator=" "
    :num-inputs="4"
    class="d-flex justify-center"
    :class="{ 'flex-row-reverse': $i18n.locale == 'ar' }"
    :should-auto-focus="true"
    :is-input-num="true"
    @on-change="handleOnChange"
    @on-complete="handleOnComplete"
  />
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input';
export default {
  components: {
    OtpInput,
  },
  props: {
    error: {
      required: true,
      default: () => false,
      type: Boolean,
    },
    clearOtp: {
      default: true,
      type: Boolean,
    },
  },
  data: () => {
    return {};
  },
  name: 'InputOtp',
  watch: {
    clearOtp() {
      if (this.clearOtp) this.handleClearInput();
    },
  },
  methods: {
    handleOnComplete(value) {
      console.log('OTP completed: ', value);
      this.$emit('complete', value);
    },
    handleOnChange(value) {
      this.$emit('input', value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
      this.$emit('cleared');
    },
  },
};
</script>

<style lang="scss">
.otp-input {
  width: 3rem;
  height: 3rem;
  padding: 5px;
  margin: 0 8px;
  border-radius: 12px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 2rem;
  color: #222;
  &.otp-error {
    border: 1px solid darkred !important;
  }
  &:focus {
    outline: none !important;
    border-color: var(--main-color);
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and(max-width: 600px) {
  .otp-input {
    width: 2.5rem;
    height: 2.5rem;
    padding: 3px;
    margin: 0 5px;
    font-size: 1rem;
    border-radius: 8px;
  }
}
</style>
