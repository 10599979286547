<template>
  <div>
  <v-container class="pb-10">
    <h2 class="text-secondary mb-4 text-uppercase font-weight-bold">{{$store.state.bookingSteps.stepsData.step2.title}}</h2>
    <v-row v-if="loading">
      <v-col
          v-for="index in 16"
          :cols="6"
          md="3"
          lg="2"
          >
        <v-skeleton-loader
            v-if="loading"
            height="100"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-item-group v-model="selected" class="stepper-padding" v-if="doctors.length" @change="nextStep">
      <v-row>
        <v-col
            :cols="6"
            md="3"
            lg="2"
            v-for="doctor in doctors"
            :key="doctor.id"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
                :color="active ? 'primary' : ''"
                class="d-flex align-center ma-1"

                elevation="0"
                style="border-radius: 15px; overflow: hidden; height: 100%"

            >
              <v-scroll-y-transition>
                <div class="text-center speciality-card"  @click="toggle">
                  <img  :src="doctor.image_url" alt="">
                  <h5>{{doctor.name}} </h5>
                  <h6>{{ $store.state.bookingSteps.selectedStepsData[$store.state.bookingSteps.stepsData.step1.selectedNameAttribute] }}</h6>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <div v-if="doctors.length === 0 && !loading">
      <p>{{$t('No doctors available')}}</p>
    </div>

  </v-container>

    <div class="step-nav">
      <div class="d-flex align-center">
        <v-btn outlined  color="secondary" class="mx-5"  @click="$emit('cancel')">
          {{$store.state.bookingSteps.stepsData.cancelButtonLabel}}
        </v-btn>

        <v-btn  color="primary" class="mx-5"  :disabled="selected === null" @click="nextStep" elevation="0">
          {{$store.state.bookingSteps.stepsData.nextButtonLabel}}
        </v-btn>
      </div>
    </div>
  </div>

</template>

<script>
import API from "@/api/dynamicAPI";
import axios from 'axios'
export default {
  name: "step1",
  data () {
    return {
      selected: null,
        doctors: [],
        loading: false
    }
  },
  async mounted() {
    this.loading = true
    let extraAttributes = {
      "specialty_id": this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step1.selectedIdAttribute]
    }
    if (this.$store.state.bookingSteps.stepsData.step2.request.type === 'get') {
      let res = await API.getAPI(this.$store.state.bookingSteps.stepsData.step2.request , this.$store.state.bookingSteps.stepsData.step2.request.headers ,extraAttributes)
      this.doctors = res.data.data
    }
    else if (this.$store.state.bookingSteps.stepsData.step2.request.type === 'post') {
      let res = await API.postAPI(this.$store.state.bookingSteps.stepsData.step2.request , this.$store.state.bookingSteps.stepsData.step2.request.headers, extraAttributes)
      this.doctors = res.data.data
    }
    this.loading = false
  },
  methods: {
    nextStep () {
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step2.selectedNameAttribute] = this.doctors[this.selected].name
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step2.selectedIdAttribute] = this.doctors[this.selected].id
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step2.selectedCodeAttribute] = this.doctors[this.selected].clinic_code
      this.$emit('nextStep')
    }
  }
}
</script>

<style lang="scss">
@media (min-width:1300px ) {
  .custom8cols {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}

.speciality-card {
  height: 100%;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  border: 2px solid #dddd;
  box-shadow: 0 3px 6px 0 rgba(51, 50, 107, 0.05);
  border-radius: 12px !important;
  overflow: hidden !important;
  margin: 3px;
  padding-top: 15px;
  img {
    margin: auto;
    height: 58px;
    border: 1px solid #dddd;
    border-radius: 100%;
    background: white;
  }
  h5 {
    color: var(--secondary-color);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  h6 {
      font-size: 12px;
    font-weight: 400;
      color: var(--main-color);
  }
}
.v-item--active {
    background: var(--main-color);
  box-shadow: none;
  .speciality-card {
    border: 2px solid var(--main-color);
    h5,h6 {
      color: white;
    }

  }
}
</style>
