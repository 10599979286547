export default class Common {
  static handleRedirection (data) {
    if (data.channel === "whatsapp")
      window.location.replace(`https://wa.me/${data.number}`);
    else if (data.channel === "facebook")
      window.location.replace(
        `https://www.facebook.com/messages/t/${data.page_id}`
      );
    else if (data.channel === "twitter")
      window.location.replace(
        `https://twitter.com/messages/${data.twitter_id}`
      );
  }
}
