var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{class:_vm.$i18n.locale,attrs:{"max-width":"700","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('div',{class:_vm.$i18n.locale + ' pa-5'},[_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"color":_vm.mainColor,"icon":"","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.step == 1)?_c('div',{class:'back-arrow-container-'+_vm.$i18n.locale+ ' hidden-md-and-up'},[_c('v-icon',{attrs:{"color":_vm.mainColor},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("mdi-arrow-right")])],1):_vm._e(),_c('div',{staticClass:"px-md-10 pb-8"},[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('h2',{staticClass:"text-capitalize ff-bold"},[_vm._v(" "+_vm._s(_vm.$t('complete checkout'))+" ")]),_c('p',{staticClass:"ff-regular"},[_vm._v(" "+_vm._s(_vm.$t('Please complete the following to complete checkout'))+" ")]),_c('v-form',{ref:"checkoutForm",staticClass:"px-md-10 py-2"},[_c('label',{staticClass:"text-capitalize ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('full name')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"outlined":"","validate-on-blur":"","dense":"","rules":[function (v) { return !!v || _vm.$t('This field is required'); }]},model:{value:(_vm.formObj.name),callback:function ($$v) {_vm.$set(_vm.formObj, "name", $$v)},expression:"formObj.name"}}),_c('label',{staticClass:"text-capitalize ff-regular",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('phone')))]),_c('v-text-field',{staticClass:"ff-regular",attrs:{"outlined":"","dense":"","type":"number","validate-on-blur":"","rules":[
                    function (v) { return !!v || _vm.$t('This field is required'); },
                    function (v) { return v.length === 11 ||
                      _vm.$t('Phone number should be 11 digit'); } ]},model:{value:(_vm.formObj.phone),callback:function ($$v) {_vm.$set(_vm.formObj, "phone", $$v)},expression:"formObj.phone"}}),(_vm.errorFromSubmitOtp)?_c('p',{style:({
                    color: 'red',
                    'font-family':
                      _vm.$i18n.locale == 'ar'
                        ? 'Tag-Regular'
                        : 'Poppins-Regular',
                  })},[_vm._v(" "+_vm._s(_vm.errorFromSubmitOtp)+" ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-capitalize ff-bold",attrs:{"color":_vm.mainColor,"dark":"","depressed":"","width":"220"},on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t('next')))])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('h2',{staticClass:"text-capitalize ff-bold"},[_vm._v(" "+_vm._s(_vm.$t('Verification'))+" ")]),_c('p',{staticClass:"ff-regular"},[_vm._v(" "+_vm._s(_vm.$t('Enter 4 digit code sent to'))+" "+_vm._s(_vm.formObj.phone)+" ")]),(_vm.step == 2)?_c('otp-input',{attrs:{"clearOtp":_vm.clearOtp,"error":_vm.otpError},on:{"input":_vm.handleOtp,"complete":_vm.handleOtp,"cleared":function($event){_vm.clearOtp = false}}}):_vm._e(),(_vm.otpError)?_c('p',{staticClass:"text-center mt-3 mb-0 ff-regular",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('Wrong code'))+" ")]):_vm._e(),_c('p',{staticClass:"my-3 font-md ff-regular"},[_vm._v(" "+_vm._s(_vm.$t('Not get code'))+" "),(_vm.countDown > 0)?_c('span',{staticClass:"ff-bold"},[_vm._v(_vm._s(_vm.countResend)+":"+_vm._s(_vm.countDown))]):_c('span',{staticClass:"text-decoration-underline ff-bold"},[_c('a',{staticClass:"black--text",on:{"click":function($event){$event.preventDefault();return _vm.resendCode($event)}}},[_vm._v(_vm._s(_vm.$t('Send again')))])])]),(!(_vm.countDown > 0))?_c('p',{staticClass:"my-3 font-md ff-regular"},[_c('span',{staticClass:"cursor-pointer text-decoration-underline",on:{"click":_vm.toPrevStep}},[_vm._v(" "+_vm._s(_vm.$t('Edit phone number ?'))+" ")])]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-capitalize mt-7 ff-bold",attrs:{"dark":"","color":_vm.mainColor,"width":"220","depressed":""},on:{"click":_vm.submitOtp}},[_vm._v(_vm._s(_vm.$t('next')))])],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }