<template>
  <v-container class="calendar-container" :style="userStyle">
    <loader :loader="loading" />
    <link-expired
      v-if="expired"
      :WARedirectNo="WARedirectNo"
      :FBRedirectPageNo="FBRedirectPageNo"
      :channel="channel"
      :TWRedirectPageNo="TWRedirectPageNo"
    />
    <v-row justify="center">
      <v-col cols="12" md="9" sm="8" class="mt-2 pb-0">
        <date-picker
          :availableDates="dates.map((e) => e.date)"
          :currentDate="currentDate"
          @input="currentDate = $event"
          :header="header"
          :desc="desc"
          :mainColor="mainColor"
          :logo="logo"
        />
      </v-col>
      <v-col cols="12" md="3" sm="4" class="outer-divider-container">
        <v-divider class="outer-divider"></v-divider>
      </v-col>
      <v-col cols="12" md="3" sm="4" class="mt-2">
        <allowed-times
          :currentDate="currentDate"
          :dates="dates"
          :WARedirectNo="WARedirectNo"
          :FBRedirectPageNo="FBRedirectPageNo"
          :channel="channel"
          :mainColor="mainColor"
          :TWRedirectPageNo="TWRedirectPageNo"
          :cancelRedirectBlock="cancelRedirectBlock"
          :cancelButtonLabel="cancelButtonLabel"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "./components/date-picker.vue";
import AllowedTimes from "./components/allowed-times.vue";
import Loader from "../../common/loader.vue";
import LinkExpired from "../../errors/link-expired";
import axios from "axios";
export default {
  name: "calendar",

  components: {
    DatePicker,
    AllowedTimes,
    Loader,
    LinkExpired,
  },

  data: () => ({
    currentDate: null,
    loading: true,
    dates: [],
    channel: "",
    WARedirectNo: null,
    FBRedirectPageNo: null,
    TWRedirectPageNo: null,
    cancelRedirectBlock: null,
    cancelButtonLabel: null,
    header: "",
    desc: "",
    mainColor: "",
    logo: "",
    loader: true,
    expired: false,
  }),
  mounted() {
    axios
      .get(
        ` ${process.env.VUE_APP_API_ROOT_CALENDAR}/extended-message?user_id=${this.$route.query.user_id}&bot_id=${this.$route.query.bot_id}`
      )
      .then((res) => {
        console.log("reeees", res);
        if (!res.data.request) {
          this.loading = false;
          if (res.data.not_found) this.expired = true;
        }
        this.channel = res.data.channel;
        this.WARedirectNo = res.data.number;
        this.FBRedirectPageNo = res.data.page_id;
        this.TWRedirectPageNo = res.data.twitter_id;
        this.cancelRedirectBlock = res.data.cancelRedirectBlock;
        this.cancelButtonLabel = res.data.cancelButtonLabel;
        this.header = res.data.webview_header;
        this.desc = res.data.webview_description;
        let headers = {};
        res.data.request.headers.forEach(
          (header) => (headers[header.key] = header.value)
        );

        if (res.data.request.type === "get") {
          this.getDynamicApi(res.data.request, headers);
        } else {
          this.postDynamicApi(res.data.request, headers);
        }
      })
      .then(() => {
        axios
          .get(
            `${process.env.VUE_APP_ENV_ROOT_CALENDAR}/bots/${this.$route.query.bot_id}/widget-settings?lang=en_EN`
          )
          .then((res) => {
            console.log("responsteeeeeee", res);
            this.mainColor = res.data.data.mainColor;
            this.logo = res.data.data.logo;
            let faviconLink = document.getElementById("favicon");
            faviconLink.setAttribute("href", res.data.data.logo);
            faviconLink.setAttribute("type", "image/png");
          })
          .then(() => (this.loading = false));
      });
    let title = document.getElementsByTagName("title")[0];
    title.innerText = "CALENDER";
  },
  methods: {
    getDynamicApi(req, headers) {
      axios
        .get(req.url, { params: req.user_attribute, headers })
        .then((response) => {
          this.dates = response.data;
          this.currentDate = response.data[0].date;
        })
        .catch((e) => console.log("error", e));
    },
    postDynamicApi(req, headers) {
      axios
        .post(req.url, req.user_attribute, { headers })
        .then((response) => {
          this.dates = response.data;
          this.currentDate = response.data[0].date;
        })
        .catch((e) => console.log("error", e));
    },
  },
  computed: {
    userStyle() {
      return {
        "--main-color": this.mainColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
