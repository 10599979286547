<template>
  <div class="location-picker">
    <div class="google-search-input d-flex align-center">
      <v-icon>mdi-magnify</v-icon>
      <input
        ref="autocomplete"
        v-model="locationSearch"
        :placeholder="$t('Search')"
        class="search-location ff-regular"
        onfocus="value = ''"
        type="text"
      />
    </div>

    <div id="g-map"></div>
    <slot name="msg" />
    <div class="location-action">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import '../../../../utils/googleMaps';
export default {
  name: 'locationPicker',
  data() {
    return {
      locationSearch: '',
      pickerMarker: null,
      map: null,
      currentLocation: { lat: '', lon: '' },
    };
  },
  props: {
    locations: {
      type: Array,
      default: () => {
        return [
          {
            lat: '30.04804295844754',
            lon: '31.236491199351715',
            info: 'Some text',
          },
          {
            lat: '31.20353825887205',
            lon: '29.918835051353874',
            info: 'Some text',
          },
        ];
      },
    },
  },
  mounted() {
    window.checkAndAttachMapScript(this.initMap);
  },
  methods: {
    async initMap() {
      this.map = new window.google.maps.Map(document.getElementById('g-map'), {
        zoom: 13,
        center: new window.google.maps.LatLng(
          this.locations[0].lat,
          this.locations[0].lon
        ),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        style: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
        ],
      });
      let infowindow = new window.google.maps.InfoWindow();

      let marker, i;

      for (i = 0; i < this.locations.length; i++) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            this.locations[i].lat,
            this.locations[i].lon
          ),
          map: this.map,
          icon: {
            url: require('../../../../assets/img/map-rest.png'),
            scaledSize: new window.google.maps.Size(25, 25), // scaled size
            origin: new window.google.maps.Point(0, 0), // origin
            anchor: new window.google.maps.Point(0, 0), // anchor
          },
        });

        window.google.maps.event.addListener(
          marker,
          'click',
          ((marker, i) => {
            return () => {
              infowindow.setContent(this.locations[i].info);
              infowindow.open(this.map, marker);
            };
          })(marker, i)
        );
      }

      this.pickerMarker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          this.currentLocation.lat,
          this.currentLocation.lon
        ),
        map: this.map,
        draggable: true,
        // clickable: true,
        icon: {
          url: require('../../../../assets/img/location-picker.png'),

          scaledSize: new window.google.maps.Size(50, 50), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
        },
      });

      window.google.maps.event.addListener(
        this.pickerMarker,
        'dragend',
        (marker) => {
          let latLng = marker.latLng;
          this.currentLocation = {
            lat: latLng.lat(),
            lon: latLng.lng(),
          };
          this.$emit('getCurrentLocation', this.currentLocation);
          this.map.panTo(
            new window.google.maps.LatLng(
              parseFloat(latLng.lat()),
              parseFloat(latLng.lng())
            )
          );
        }
      );

      window.google.maps.event.addListener(this.map, 'click', (event) => {
        setTimeout(() => {
          console.log(
            'event for google ',
            event.latLng.lat(),
            event.latLng.lng()
          );
          this.currentLocation = {
            lat: event.latLng.lat(),
            lon: event.latLng.lng(),
          };
          this.$emit('getCurrentLocation', this.currentLocation);
          this.pickerMarker.setPosition(
            new window.google.maps.LatLng(
              event.latLng.lat(),
              event.latLng.lng()
            )
          );
          this.map.panTo(
            new window.google.maps.LatLng(
              parseFloat(event.latLng.lat()),
              parseFloat(event.latLng.lng())
            )
          );
        }, 200);
      });

      this.initLocationSearch();

      let currentLocation = await this.getCurrentLocation();
      this.pickerMarker.setPosition(
        new window.google.maps.LatLng(currentLocation.lat, currentLocation.lon)
      );
      this.currentLocation = {
        lat: currentLocation.lat,
        lon: currentLocation.lon,
      };

      this.$emit('getCurrentLocation', currentLocation);

      this.map.panTo(
        new window.google.maps.LatLng(
          parseFloat(this.currentLocation.lat),
          parseFloat(this.currentLocation.lon)
        )
      );
    },
    getCurrentLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              let geocoder = new window.google.maps.Geocoder();
              let latlng = {
                lat: parseFloat(position.coords.latitude),
                lng: parseFloat(position.coords.longitude),
              };
              geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === 'OK') {
                  if (results[0]) {
                    this.selectedLocation = results[0].formatted_address;
                    this.currentLocation = {
                      lat: position.coords.latitude,
                      lon: position.coords.longitude,
                    };
                    resolve(this.currentLocation);
                  } else {
                    reject();
                  }
                } else {
                  reject();
                }
              });
            },
            (e) => {
              reject();
            }
          );
        } else {
          reject();
        }
      });
    },

    initLocationSearch() {
      let autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.autocomplete
      );
      autocomplete.addListener('place_changed', () => {
        let place = autocomplete.getPlace();
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.pickerMarker.setPosition(new window.google.maps.LatLng(lat, lon));
        this.currentLocation = {
          lat: lat,
          lon: lon,
        };
        console.log(this.currentLocation);
        this.map.panTo(new window.google.maps.LatLng(lat, lon));
        this.$emit('getCurrentLocation', this.currentLocation);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.location-picker {
  position: relative;
  .location-action {
    width: 100%;
    padding: 2rem 12px;
    height: auto;
    min-height: 15vh;
    box-shadow: 0px 0px 10px 1px rgb(163, 163, 163);
    bottom: 0;
    left: 0;
    z-index: 4;
    position: absolute;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 90%;
      /* flex-wrap: wrap !important; */
      display: block;
      /* justify-content: center; */
    }
    .v-btn {
      text-transform: capitalize !important;
    }
  }

  .location-disabled-msg {
    width: 100%;
    left: 0;
    position: absolute;
    /* top: 20px; */
    display: flex;
    justify-content: center;
    top: 9.5% !important;

    .msg {
      font-size: 14px;
      border: 1px solid rgba(220, 220, 220, 0.137);
      border-bottom: none;
      background: #dc0001;
      position: absolute;
      color: white;
      top: 0px;
      padding: 1px 10px;
      text-align: center;
      /* left: -40px; */
    }
  }
}

.search-locations-bar {
  max-width: 750px;
  display: block;
  margin: auto;
}

#g-map {
  height: 100vh;
}

.google-search-input {
  position: absolute;
  /* top: 10%; */
  top: 14.5% !important;

  left: 50%;
  z-index: 4;
  background: white;
  width: 70%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
  height: 41px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #eee;
  padding: 0 10px;
  margin: auto;
  .search-location {
    display: block;
    height: 41px;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    outline: none;
    line-height: 20px;
    border-radius: 10px;
  }
}

@media (max-width: 960px) {
  .location-action {
    .v-btn {
      width: 150px !important;
      margin-left: 5px !important;
      margin-right: 5px !important;
      text-transform: capitalize !important;
    }
  }
  .location-picker {
    .location-disabled-msg {
      top: 8.5% !important;
    }
  }
  .google-search-input {
    top: 16% !important;
  }
}

@media screen and (max-width: 600px) {
  .location-picker {
    .location-disabled-msg {
      top: 9.5% !important;
      .msg {
        font-size: 12px !important;
      }
    }
  }
  .google-search-input {
    top: 17% !important;
  }
}

@media (max-width: 400px) {
  .location-action {
    > div {
      width: 90%;
      flex-wrap: wrap !important;
      display: flex;
      justify-content: center;
      .v-btn {
        width: 100% !important;
        margin: 8px 0;
      }
    }
  }
}
</style>
