<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        :class="
          this.pageStatus.showForm
            ? ` d-none d-md-block ma-0 pa-0`
            : `d-block ma-0 pa-0`
        "
        :md="this.pageStatus.showForm || this.pageStatus.showAddresses ? 8 : 12"
      >
        <div :class="`maps-container `">
          <div
            class="back-arrow-container d-block d-md-none"
            v-if="pageStatus.showDetails"
          >
            <v-icon
              :color="mainColor"
              size="25"
              class="cursor-pointer"
              @click="
                pageStatus = {
                  ...pageStatus,
                  showDetails: false,
                }
              "
            >
              mdi-arrow-left</v-icon
            >
          </div>

          <v-overlay :value="dialog" class="d-flex justify-center">
            <div class="overlay-container pa-5">
              <div class="close-btn-container mb-1">
                <v-icon
                  :color="mainColor"
                  size="25"
                  class="cursor-pointer"
                  @click="dialog = false"
                >
                  mdi-close</v-icon
                >
              </div>
              <h4 class="ff-bold font-xl dark-gray-font mb-4 px-2 ff-bold">
                {{ $t('We are sorry') }}
              </h4>
              <p class="ff-medium font-md dark-gray-font mb-7 px-2 ff-regular">
                {{ error }}
              </p>

              <div
                class="d-flex justify-center btn-container mb-6 ff-regular"
                v-if="showBTN"
              >
                <v-btn
                  dark
                  :color="mainColor"
                  @click="dialog = false"
                  depressed
                >
                  {{ $t('Change Location') }}
                </v-btn>
              </div>
            </div>
          </v-overlay>

          <location-picker
            v-if="locations.length"
            :locations="locations"
            @getCurrentLocation="getCurrentLocation"
          >
            <template v-slot:msg>
              <div :class="`location-disabled-msg ${$i18n.locale}`">
                <div
                  class="msg"
                  :style="{
                    'font-family':
                      $i18n.locale == 'ar' ? ' Tag-Bold' : 'Poppins-Regular',
                  }"
                >
                  {{ $t('map_static_msg') }}
                </div>
              </div>
            </template>

            <template v-slot:actions>
              <div>
                <v-row class="d-flex justify-center">
                  <v-btn
                    v-if="!pageStatus.showDetails"
                    @click="checkAvailableBranches"
                    depressed
                    dark
                    width="200"
                    class="mx-3 ff-bold"
                    height="45"
                    elevation="0"
                    :disabled="!currentLocation"
                    :color="mainColor"
                    >{{ $t('Confirm Location') }}
                  </v-btn>
                  <v-btn
                    v-else
                    @click="confirmAddress"
                    depressed
                    dark
                    width="200"
                    class="mx-3 ff-bold"
                    height="45"
                    elevation="0"
                    :disabled="!currentLocation"
                    :color="mainColor"
                    >{{ $t('Confirm Location') }}
                  </v-btn>
                  <v-btn
                    v-if="!pageStatus.showDetails"
                    outlined
                    :color="mainColor"
                    width="200"
                    class="mx-3 ff-bold"
                    height="45"
                    @click="pickAddressAction"
                  >
                    {{ $t('Pick address') }}
                  </v-btn>
                  <v-btn
                    v-else
                    outlined
                    :color="mainColor"
                    width="200"
                    class="mx-3 ff-bold"
                    height="45"
                    @click="resetAddress"
                  >
                    {{ $t('Reset Location') }}
                  </v-btn>
                </v-row>
              </div>
            </template>
          </location-picker>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="ma-0 pa-0"
        v-if="this.pageStatus.showForm"
      >
        <add-location-manually />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="list-addresses-div ma-0 pa-0"
        v-if="this.pageStatus.showAddresses"
      >
        <list-addresses />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import locationPicker from '@/views/ordering/components/map/locationPicker';
import AddLocationManually from '@/views/ordering/components/map/add-location-manually';
import ListAddresses from '@/views/ordering/components/map/list-addresses';
import axios from 'axios';
import { Formatter } from 'sarala-json-api-data-formatter';
const formatter = new Formatter();

export default {
  components: { locationPicker, AddLocationManually, ListAddresses },
  name: 'GoogleMap',
  props: ['locations'],

  data() {
    return {
      dialog: false,
      error: null,
      currentLocation: {},
      showBTN: true,
    };
  },
  mounted() {},

  methods: {
    reg(value) {
      if (value) {
        if (
          value.search(/[!@#$%^&*_+=\[\]{};:\\|<>?~]/i) === -1 &&
          !value.toLowerCase().includes('unnamed road')
        ) {
          return value;
        } else return '';
      } else return '';
    },
    resetAddress() {
      this.address = {
        address_id: null,
        type: '',
        street: '',
        building: '',
        apartment: '',
        floor: '',
        notes: '',
      };
      this.map_address = {
        lat: '',
        lon: '',
      };
      this.pageStatus = {
        showAddresses: false,
        showDetails: false,
        showForm: false,
      };
    },
    confirmAddress() {
      let dataForExistedAddress = {
        order_id: this.order_id,
        address_id: this.address.address_id,
      };

      let dataForNonExisted = {
        order_id: this.order_id,
        address_id: null,
        address: this.address.street,
        latitude: this.map_address.lat,
        longitude: this.map_address.lon,
        building_number: this.address.building,
        floor_number: this.address.floor,
        apartment_number: this.address.apartment,
        address_type: this.address.type,
        note: this.address.notes,
      };
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_BEENA_PHASE2}/${this.$i18n.locale}/addresses`,
        headers: {},
        data: this.address.address_id
          ? dataForExistedAddress
          : dataForNonExisted,
      })
        .then((res) => {
          let data = formatter.deserialize(
            JSON.parse(JSON.stringify(res.data))
          );
          this.basket = data.meals.data;
          this.paymentDetails = data.cost;
          this.order = data;
          this.notes = data.notes || '';
          this.meals = data.menu.data;
          this.address = { ...this.address, address_id: data.address.data.id };

          this.$router.push({
            path: '/order/ext',
            query: {
              user_id: this.$route.query.user_id,
              bot_id: this.$route.query.bot_id,
            },
          });
        })
        .catch((e) => {
          this.dialog = true;
          this.error = e.response.data.errors[0].detail;
        });
    },
    getCurrentLocation(loc) {
      this.currentLocation = loc;
    },

    checkAvailableBranches() {
      if (!this.currentLocation.lat) {
        this.dialog = true;
        this.showBTN = false;
        this.error = this.$t('map_static_msg');

        return;
      }
      this.showBTN = true;
      this.$store.dispatch('setMapAddress', this.currentLocation);
      const order = this.$store.state.order_ext.order_id;
      let locale = localStorage.getItem('locale');

      axios
        .get(
          `${process.env.VUE_APP_BEENA_PHASE2}/${locale}/vendors/customer/check-available-branches?order_id=${order}&latitude=${this.currentLocation.lat}&longitude=${this.currentLocation.lon}`
        )
        .then(({ data }) => {
          const address = formatter.deserialize(
            JSON.parse(JSON.stringify(data))
          );
          const addressData = {
            address_id: null,
            type: address.address_type,

            street: this.reg(address.address),
            building: address.building_number || '',
            apartment: address.apartment_number,
            floor: address.floor_number,
            notes: address.notes,
          };

          this.$store.dispatch('setAddress', addressData);
          this.pageStatus = {
            ...this.pageStatus,
            showForm: true,
            showAddresses: false,
          };
        })
        .catch((e) => {
          this.dialog = true;
          console.log('error', e);
          this.error = e.response.data.errors[0].detail;
        });
    },
    pickAddressAction() {
      this.pageStatus = {
        ...this.pageStatus,
        showForm: false,
        showAddresses: true,
      };
    },
  },
  computed: {
    address: {
      get() {
        return this.$store.state.order_map.address;
      },
      set(value) {
        this.$store.dispatch('setAddress', value);
      },
    },
    order: {
      get() {
        return this.$store.state.order_ext.order;
      },
      set(value) {
        this.$store.commit('updateOrder', value);
      },
    },
    paymentDetails: {
      get: function () {
        return this.$store.state.order_ext.paymentDetails;
      },
      set: function (value) {
        this.$store.commit('updatePaymentDetails', value);
      },
    },

    basket: {
      get: function () {
        return this.$store.state.order_ext.basket;
      },
      set: function (value) {
        this.$store.commit('updateBasket', value);
      },
    },
    meals: {
      get() {
        return this.$store.state.order_ext.meals;
      },
      set(value) {
        this.$store.commit('updateMeals', value);
      },
    },
    map_address: {
      get: function () {
        return this.$store.state.order_map.mapAddress;
      },
      set: function (value) {
        this.$store.commit('setMapAddress', value);
      },
    },
    order_id: {
      get: function () {
        return this.$store.state.order_ext.order_id;
      },
      set: function (value) {
        this.$store.commit('updateOrderId', value);
      },
    },

    pageStatus: {
      get: function () {
        return this.$store.state.order_map.pageStatus;
      },
      set: function (value) {
        this.$store.dispatch('setPageStatus', value);
      },
    },
    mainColor: {
      get() {
        return this.$store.state.order_ext.mainColor;
      },
      set(value) {
        this.$store.commit('updateMainColor', value);
      },
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/add-location.scss';
</style>
